import { CanMatchFn, Route, Router } from '@angular/router';
import { UserRole, UserService } from './user.service';
import { inject } from '@angular/core';
import { first, map } from 'rxjs';

export interface RolesRouteData {
    roles: UserRole | UserRole[];
}

export const canMatchRole: CanMatchFn = (route: Route) => {
    const routeData = route.data as RolesRouteData;
    const requiredRoles = Array.isArray(routeData.roles) ? routeData.roles : [routeData.roles];
    const userService = inject(UserService);
    const router = inject(Router);
    return userService.roles$.pipe(
        first(),
        map((roles) => {
            if (requiredRoles.some((role) => roles.includes(role))) {
                return true;
            } else {
                return router.createUrlTree(['/']);
            }
        }),
    );
};
